import React from "react";
import { Link } from "gatsby";
import PrintButton from "./PrintButton";

const NextPrevButtons = ({ next, prev, MiddleButton = PrintButton }) => {
  return (
    <div className="wrapper-btn print">
      {prev === null ? (
        <div></div>
      ) : (
        <Link className="button print" to={prev.slug}>
          <span className="icon icon-chevron-right"></span>
          {prev.title}
        </Link>
      )}
      <MiddleButton />
      {next === null ? (
        <div></div>
      ) : (
        <Link className="button print" to={next.slug}>
          {next.title}
          <span className="icon icon-chevron-left"></span>
        </Link>
      )}
    </div>
  );
};

export default NextPrevButtons;
