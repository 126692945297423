import React from "react";

const ExerciseTitle = ({ title, rank, difficulty }) => {
  const addStars = number => {
    const stars = [];
    for (let index = 1; index <= 5; index++) {
      if (index <= number) {
        stars.push(<span key={index}>&#9733;</span>);
      } else {
        stars.push(<span>&#9734;</span>);
      }
    }
    return stars;
  };
  return (
    <h4 style={{ marginBottom: 0 }}>
      {`${title} ${rank} `}
      <span style={{ color: "gold" }}>{addStars(difficulty)}</span>
    </h4>
  );
};

export default ExerciseTitle;
