import React, { lazy, Suspense } from "react";

import Loader from "components/loader";

const ExerciseSolution = lazy(() => import("components/exerciseSolution"));
const UserProvider = lazy(() => import("context/userContext"));

const isSSR = typeof window === "undefined";

const LazyExerciseSolution = ({ printInfo, location, autoShow }) => {
  return (
    !isSSR && (
      <Suspense fallback={<Loader />}>
        <ExerciseSolution
          printInfo={printInfo}
          location={location}
          autoShow={autoShow}
        />
      </Suspense>
    )
  );
};

export default LazyExerciseSolution;
