import React from "react";

const PrintButton = () => {
  const printHandler = () => {
    window.print();
  };
  return (
    <button className="button primary print" onClick={printHandler}>
      <span className="icon icon-print"></span>
      إطبع الدرس
    </button>
  );
};

export default PrintButton;
